.txtNumerico input {
  text-align: right;
  padding-right: 5px;
}

.save-button-div div.dx-button {
  background-color: #5cb85c !important;
  color: white;
}

.save-create-button-div div.dx-button {
  background-color: #5c84b8 !important;
  color: white;
}

.buttonItem {
  width: 100% !important;
  padding: 0 !important;
  justify-content: center !important;
  text-align: center !important;
  margin: 0 !important;
}