.notfound {
  min-height: 70vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card-content {
  width: 100%;
}

.select-box {
  margin: 50px 10px ;
  padding: 10px 5px;
  border-radius: 6px;
  font-size: 14px;
}

.select-box:active {
  border: 1px solid black;
  border-radius: 6px;
}

.logo {
  max-width: 600px;
}



@media (min-width: 420px) and (max-width: 650px) {
 
  .logo {
    max-width: 400px;
  }
}

@media (min-width: 200px) and (max-width: 420px) {
 
  .logo {
    max-width: 300px;
  }
}