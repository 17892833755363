body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.normal-button .dx-button-content {
  background-color: #425eb9;
  color: white;
}

.normal-button .dx-button,
.normal-button .dx-button-normal,
.normal-button .dx-button-mode-contained,
.normal-button .dx-widget,
.normal-button .dx-button-has-text {
  background-color: #425eb9 !important;
}

.normal-button .dx-button-content:hover,
.normal-button .dx-button:hover,
.normal-button .dx-button-normal:hover,
.normal-button .dx-button-mode-contained:hover,
.normal-button .dx-widget:hover,
.normal-button .dx-button-has-text:hover {
  opacity: 0.95;
}

.success-button .dx-button-content {
  background-color: #419641;
  color: white;
}

.success-button .dx-button,
.success-button .dx-button-normal,
.success-button .dx-button-mode-contained,
.success-button .dx-widget,
.success-button .dx-button-has-text {
  background-color: #419641 !important;
}

.success-button .dx-button-content:hover,
.success-button .dx-button:hover,
.success-button .dx-button-normal:hover,
.success-button .dx-button-mode-contained:hover,
.success-button .dx-widget:hover,
.success-button .dx-button-has-text:hover {
  opacity: 0.95;
}

.txtNumerico input {
  text-align: right;
  padding-right: 5px;
}
