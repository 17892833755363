.dx-theme-material-typography h2 {
    font-size: 35px;
    font-weight: lighter;
    color: #333333;
    margin-left: 50px !important;
    margin-top: 40px !important;
}

.dx-theme-material-typography h5 {
    font-size: 20px;
    font-weight: lighter;
    color: #333333;
    align-self: baseline;
    margin-top: 0px;
    margin-left: 10px;
}

.dx-card {
    //display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: baseline;
    width: 90%;
    height: 80%;
    margin: 30px 40px auto 40px;
    padding: 40px !important;
}

.form {
    display: flex;
    flex-direction: row;
}

.buttons {
    margin-top: 20px;
}

.filter-button {
    margin-left: 10px;
}

.all-button {
    margin-left: 10px;
}

.clear-button {
    margin-left: 10px;
}

.filter-button .dx-state::hover {
    background-color: rgb(255, 127, 80);
}

.filtro-form {
    margin-left: 10px;
}