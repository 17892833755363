.dx-theme-material-typography .msg {
    color: red;
    font-size: 16px;
    font-weight: normal;
    margin-left: 0;
}

.dx-theme-material-typography .sm-mes-ano {
    font-weight: 500;
    margin-bottom: 0px;
    margin-top: 10px;
}

.dx-theme-material-typography .sm-confirmar {
    margin-left: 0;
    margin-bottom: 20px;

}

.buttons-smovimento {
    margin-top: 0px;
}