
.btn-emissao-nf{
    margin-left: 10px;
    background-color: #5cb85c;
    color: white;
}


.dx-theme-material-typography .info {
    margin-left: 0 !important; 
}

.dx-field {
    margin: 0 0 10px 10px;
}

.dx-field-label {
    overflow: visible !important;
    font-size: 16px;
    color: #555555;
    width: 100px !important;
}


.dx-field-value {
    width: 2% !important;
}

.dx-field-item-label-text {
    color: #555555;
}


.dx-widget input .dx-widget textarea {
    text-align: right;
}

.txtEmissaoNotaNumerico input{
    text-align: right;
}

.dx-state-readonly{
    border: none !important;
    background-color: #F4F6F6  !important;
  }