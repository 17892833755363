.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5.00);
  display: flex;
  height: 100%;
  width: 100%;
}

.app .content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.app .content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.side-navigation-menu .menu-container .dx-treeview .dx-treeview-node[aria-level='2'] .dx-treeview-item-content {
  font-weight: normal;
  padding: 0px 5px !important;
}

.dx-popup-normal .dx-toolbar-after {
  padding-right: 10px !important;
}

.dx-popup-normal .dx-toolbar-before {
  padding-left: 20px !important;
}

.dx-popup-normal .dx-toolbar-after:hover {
  color: red !important;
}

.dx-popup-normal .dx-toolbar-after .dx-button-content:hover {
  opacity: 0.6;
  background-color: red !important;
  transition: 0.2s !important;
}

.dx-popup-normal .dx-toolbar-after .dx-icon-close:hover {
  color: white !important;
}