.recuperar-senha-main-div {
  width: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.recuperar-senha-card {
  border: 1px solid rgb(165, 165, 165);
  border-radius: 8px;
  background-color: white;
  padding: 60px;
  width: 400px;
  display: flex;
  flex-direction: column;
}

.link-go-back {
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #337ab7;
  text-decoration: underline;
}

.link-go-back:hover {
  cursor: pointer;
}
