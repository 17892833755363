.dx-theme-material-typography .cm-mes-ano {
    font-weight: 500;
    background-color: #f5f5f5;
    margin-left: 50px;
}

.dx-theme-material-typography .msg {
    color: red;
    font-size: 16px;
    font-weight: normal;
    margin-left: 0;
}

.dx-theme-material-typography .notas {
    margin-left: 0;
    font-size: 14px;
}

.dx-theme-material-typography .atividades {
    margin-left: 0; 
    font-size: 14px;  
}

.dx-theme-material-typography .conf {
    margin-left: 50px;
    margin-top: 30px;
    background-color: #f5f5f5;
}

.buttons-cmovimento {
    margin-left: 50px;
    margin-top: -15px;
}

.cancelar-button {
    margin-left: 10px;
}